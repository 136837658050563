export function extractRedirectFromURL(url) {
  const urlParams = new URLSearchParams(url);
  const encodedRedirect = urlParams.get("redirect");
  const redirect = encodedRedirect
    ? decodeURIComponent(encodedRedirect)
    : "/a/dashboard";
  return redirect;
}

export function extractParam(url, param) {
  const urlParams = new URLSearchParams(url);
  const value = urlParams.get(param);
  return value;
}
