import { useEffect, createContext, useRef } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { configExtras } from "./utils/config";
import { UserProvider } from "./contexts/userContext";

// Components imports
import { AuthenticationContainer } from "./pages/AuthenticationContainer/AuthenticationContainer";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { getCookie } from "./helpers/cookieHelpers";
import { verifyToken } from "./services/authService";
import {
  extractParam,
  extractRedirectFromURL,
} from "./helpers/extractRedirectFromURL";
import { generateUrl } from "./helpers/generateURL";

export const AuthInfo = createContext(null);

const GOOGLE_CLIENT_ID = configExtras.GOOGLE_CLIENT_ID;

function App() {
  const authInfo = useRef(null);

  useEffect(() => {
    if (window.location.pathname === "/logout") {
      return; // Exit the effect early if on /logout
    }
    //if token exists, we redirect the user to dev.codedesign.ai

    async function verifyTokenCall() {
      console.log("verifyingToken");
      const token = getCookie("token");
      const uRI = getCookie("userId");

      if (token && uRI) {
        const user = await verifyToken(token, uRI);
        if (user) {
          const redirect = extractRedirectFromURL(window.location.search);
          generateUrl(user, redirect);
        }
      }
    }
    const authType = extractParam(window.location.search, "authorization_type");
    const clientId = extractParam(window.location.search, "client_id");
    const state = extractParam(window.location.search, "state");
    const redirect_uri = extractParam(window.location.search, "redirect_uri");
    if (authType && clientId && state && redirect_uri) {
      authInfo.current = {
        authorization_type: authType,
        client_id: clientId,
        state: state,
        redirect_uri: redirect_uri,
      };
    }
    if (!authType) {
      verifyTokenCall();
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <UserProvider>
        <AuthInfo.Provider value={authInfo}>
          <Router>
            <div className="App">
              <Header />
              <Routes>
                <Route path="/*" element={<AuthenticationContainer />} />
              </Routes>
              <Footer />
            </div>
          </Router>
        </AuthInfo.Provider>
      </UserProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
