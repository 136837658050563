import { environmentURLs } from "../utils/environmentURLs";
import { setCookie } from "./cookieHelpers";
const { frontendUrl : url } = environmentURLs;


function setCookiesForEnvironment(env, token, _id) {
  let domain;
  if (env?.startsWith('dev')) {
      domain = '.codedesign.app';
      setCookie("token", token, domain);
      setCookie("userId", _id, domain);
      domain = 'staging-accounts.codedesign.app';
      setCookie("token", token, domain);
      setCookie("userId", _id, domain);
  } else if (env?.startsWith('prod')) {
      domain = '.codedesign.ai';
      setCookie("token", token, domain);
      setCookie("userId", _id, domain);
      domain = 'accounts.codedesign.ai';
      setCookie("token", token, domain);
      setCookie("userId", _id, domain);
  }
}

export const generateUrl = (user, redirect="/a/dashboard") => {
 
    const {_id, token} = user;
    const environment = process.env.REACT_APP_ENV ||"development";

    setCookiesForEnvironment(environment, token, _id);
    const uRI = _id;
    let authURL;
    if (redirect) {
      authURL = `${url}/${uRI}?action=login&uRI=${uRI}&redirect=${redirect}`;
    } else {
      //the default case, redirect to dev.codedesign.ai/
     authURL = `${url}/${uRI}?action=login&uRI=${uRI}&redirect=${encodeURIComponent(
        "/"
      )}`;
    }
      window.location = authURL;
  };
