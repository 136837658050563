import { environmentURLs } from "../utils/environmentURLs";
const { frontendAPIUrl } = environmentURLs;

const handleUserLogin = async (email, password, AuthInfo) => {
  const requestBody = {
    email: email,
    password: password,
    client_id: AuthInfo ? AuthInfo.client_id : null,
    redirect_uri: AuthInfo ? AuthInfo.redirect_uri : null,
    state: AuthInfo ? AuthInfo.state : null,
  };
  try {
    const response = await fetch(
      `${frontendAPIUrl}/login?authorization_type=${
        AuthInfo ? AuthInfo.authorization_type : "default"
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );

    const result = await response.json();
    console.log("result is", result);
    if (result.error) {
      throw new Error(result.authFlag || "internalServerError");
    }
    return { data: result.data };
  } catch (thrownError) {
    console.error("An error occurred:", thrownError);
    throw thrownError;
  }
};

const handleUserRegistration = async (name, email, password, redirectTo) => {
  try {
    const response = await fetch(`${frontendAPIUrl}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        password: password,
        redirectTo,
      }),
    });

    const result = await response.json();
    if (result.error) {
      throw new Error(result.authFlag || "internalServerError");
    }

    return { data: result.data };
  } catch (thrownError) {
    console.error("An error occurred:", thrownError);
    throw thrownError;
  }
};

const generateMagicLink = async (params, AuthInfo) => {
  const { email, name, source, redirectTo } = params;

  try {
    const response = await fetch(
      `${frontendAPIUrl}/login/generate-magic-link?authorization_type=${
        AuthInfo ? AuthInfo.authorization_type : "default"
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          name: name,
          redirectTo: redirectTo,
          source: source,
          client_id: AuthInfo ? AuthInfo.client_id : null,
          redirect_uri: AuthInfo ? AuthInfo.redirect_uri : null,
          state: AuthInfo ? AuthInfo.state : null,
        }),
      }
    );

    const result = await response.json();
    console.log("result is: ", result.error);
    if (result.error) {
      if (result.error === "Name is required for new user registration") {
        throw new Error("Name is required for new user registration");
      }
      throw new Error(result.authFlag || "internalServerError");
    }

    return true;
  } catch (thrownError) {
    console.error("An error occurred:", thrownError);
    throw thrownError;
  }
};

async function verifyToken(token, id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  try {
    const response = await fetch(
      `${frontendAPIUrl}/user/${id}/`,
      requestOptions
    );

    const result = await response.json();
    if (result.error) {
      throw new Error(result.authFlag || "internalServerError");
    }
    const user = result.data;
    return user;
  } catch (thrownError) {
    console.error("An error occurred:", thrownError);
    throw thrownError;
  }
}

const verifyMagicLink = async (token, authType, authInfo) => {
  if (!token) {
    throw new Error("Invalid token. Please try again.");
  }
  try {
    const response = await fetch(
      `${frontendAPIUrl}/login/verify-magic-link?authorization_type=${
        authType || "default"
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: token, state: authInfo.state || "none" }),
      }
    );

    const result = await response.json();
    if (result.error) {
      throw new Error(result.authFlag || "internalServerError");
    }

    return { data: result.data };
  } catch (thrownError) {
    console.error("An error occurred:", thrownError);
    throw thrownError;
  }
};

const validateAndProcessToken = async (token, AuthInfo) => {
  try {
    const response = await fetch(
      `${frontendAPIUrl}/login/auth/google?authorization_type=${
        AuthInfo ? AuthInfo.authorization_type : "default"
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...token,
          client_id: AuthInfo ? AuthInfo.client_id : null,
          redirect_uri: AuthInfo ? AuthInfo.redirect_uri : null,
          state: AuthInfo ? AuthInfo.state : null,
        }),
      }
    );

    const result = await response.json();
    if (result.error) {
      throw new Error(result.authFlag || "internalServerError");
    }

    return { data: result.data };
  } catch (thrownError) {
    console.error("An error occurred:", thrownError);
    throw thrownError;
  }
};

export {
  handleUserLogin,
  handleUserRegistration,
  generateMagicLink,
  verifyMagicLink,
  validateAndProcessToken,
  verifyToken,
};
